import {RiInstagramFill} from "react-icons/ri";
import {BsGithub} from "react-icons/bs";
import {FaTelegram} from "react-icons/fa";
import {IoLogoLinkedin} from "react-icons/io";
import {Config} from "./config/Config";

function App() {
  return (
   <div>

       <div className="flex justify-center items-center h-screen w-full">
           <div className="flex flex-col justify-center items-center m-10 ">
               <div className="flex justify-center items-center">
                   <img src={Config.logo} alt="nilfam.tech"/>
               </div>
               <div className='flex flex-row gap-4 py-4 mb-10 '>
                   <RiInstagramFill className='bg-white text-sky-600 rounded-full p-2 hover:bg-sky-600 hover:text-white' size={45}/>
                   <BsGithub className='bg-white text-sky-600 rounded-full p-2 hover:bg-sky-600 hover:text-white' size={45}/>
                   <FaTelegram className='bg-white text-sky-600 rounded-full p-2 hover:bg-sky-600 hover:text-white' size={45}/>
                   <IoLogoLinkedin className='bg-white text-sky-600 rounded-full p-2 hover:bg-sky-600 hover:text-white' size={45}/>
               </div>
           </div>
       </div>

   </div>
  );
}

export default App;
